import { mapGetters, mapActions } from 'vuex'
import { showVueToast } from '@/utils'
import dayjs from 'dayjs'
export default {
  name: 'open-day',
  data: () => ({
    forDate: '-',
    disable: false,
    isAvailable: false,
    isInformationModal: false,
    slug: 'open-day',
    coreClassId: '',
    checkDate: '',
    startTime: '-',
    endTime: '-',
    fullName: '',
    email: '',
    phoneNumber: ''
  }),
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    TextField: () => import(/* webpackChunkName: "icon" */ '@/components/forms/TextField')
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    date: function () {
      this.forDate !== '-' ? (this.isAvailable = true) : (this.isAvailable = false)
      return this.forDate !== '-' ? this.forDate : '-'
    },
    time: function () {
      return this.startTime !== '-' && this.endTime !== '-' ? `${this.startTime} - ${this.endTime} (Your local time)` : '-'
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  mounted() {
    this.getCoreClassId()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('program', ['getProgramByWebSlug', 'getLmsClasses', 'getFormToken', 'newEnrollV2']),
    getCoreClassId() {
      this.getProgramByWebSlug({
        slug: this.slug
      })
        .then((res) => {
          this.coreClassId = res.data.data.id
          this.checkAvailableSchedule()
        })
        .catch(() => {})
    },
    checkAvailableSchedule() {
      this.getLmsClasses({
        coreClassId: this.coreClassId,
        params: {
          page: 0,
          size: 1,
          upcoming: true,
          sortBy: 'startAt',
          direction: 'ASC'
        }
      })
        .then((response) => {
          this.checkDate = response.data.data.data[0].startDateTime
          this.forDate = dayjs(response.data.data.data[0].startDateTime).format('dddd, DD MMMM YYYY')
          this.startTime = dayjs(response.data.data.data[0].startDateTime).format('HH.mm')
          this.endTime = dayjs(response.data.data.data[0].endDateTime).format('HH.mm')
        })
        .catch(() => {})
    },
    async register() {
      const delay = (ms) => new Promise((resolve, reject) => setTimeout(resolve, ms))
      this.disable = true
      if (this.validateForm()) {
        if (this.isAvailable) {
          if (dayjs(new Date()).isBefore(this.checkDate)) {
            this.getFormToken()
              .then((response) => this.doRegister(response.data.data))
              .catch(() => {
                showVueToast('There was a problem, please try again later!', 'error', 3000)
                window.location.reload()
              })
          } else {
            this.toggle()
          }
        } else {
          showVueToast('The Open Day is not available, please contact our admin!', 'error', 3000)
        }
      }
      await delay(3500)
      this.disable = false
    },
    doRegister(token) {
      this.showLoading()
      this.newEnrollV2({
        payloads: {
          coreClassId: this.coreClassId,
          email: this.email,
          name: this.fullName,
          mobilePhone: this.phoneNumber
        },
        formToken: token
      }).then(
        () => {
          this.refreshInput()
          this.hideLoading()
          this.$router.push('/thankyou/open-day')
        },
        () => {
          this.hideLoading()
          showVueToast('You have already enroll for this schedule!', 'error', 3000)
        }
      )
    },
    refreshInput() {
      this.email = ''
      this.fullName = ''
      this.phoneNumber = ''
    },
    toggle() {
      this.isInformationModal = true
    },
    reload() {
      window.location.reload()
    },
    validateForm() {
      // eslint-disable-next-line
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      const pattern = /^[0-9]{10,13}$/
      if (this.fullName === '') {
        showVueToast('Name is required!', 'error', 3000)
        return false
      } else if (this.email === '') {
        showVueToast('Email is required!', 'error', 3000)
        return false
      } else if (!regex.test(this.email)) {
        showVueToast('Email format is invalid!', 'error', 3000)
        return false
      } else if (this.phoneNumber === '') {
        showVueToast('Phone number is required!', 'error', 3000)
        return false
      } else if (!pattern.test(this.phoneNumber)) {
        showVueToast('Your phone number format is invalid! (ex: 081234567890)', 'error', 3000)
        return false
      } else {
        return true
      }
    }
  }
}
